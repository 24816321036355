// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-build-a-quote-index-js": () => import("/opt/build/repo/src/pages/build-a-quote/index.js" /* webpackChunkName: "component---src-pages-build-a-quote-index-js" */),
  "component---src-pages-build-a-quote-thanks-js": () => import("/opt/build/repo/src/pages/build-a-quote/thanks.js" /* webpackChunkName: "component---src-pages-build-a-quote-thanks-js" */),
  "component---src-pages-build-info-index-js": () => import("/opt/build/repo/src/pages/build-info/index.js" /* webpackChunkName: "component---src-pages-build-info-index-js" */),
  "component---src-pages-build-info-thanks-js": () => import("/opt/build/repo/src/pages/build-info/thanks.js" /* webpackChunkName: "component---src-pages-build-info-thanks-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("/opt/build/repo/src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

